<template>
  <div>
    <div class="bannerContainer">
      <div class="banner w">
        <div class="title">{{ $t('AI收银解决方案') }}</div>
        <div class="info">
          <div>{{ $t('支持零代码弹窗插件无缝对接，') }}</div>
          <div>{{ $t('提供多平台、多协议SDK集成方案。') }}</div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="topContainer">
        <div class="top w">
          <div>
            <div class="item">
              <div class="i"></div>
              <div class="text">
                {{ $t('自动匹配或自动拍摄商品展示图片，并支持手动编辑。') }}
              </div>
            </div>
            <div class="item">
              <div class="i"></div>
              <div class="text">
                {{ $t('支持市面主流的POS收银软件，远程取数、云端自动升级。') }}
              </div>
            </div>
            <div class="item">
              <div class="i"></div>
              <div class="text">
                {{
                  $t('自动精准识别，支持拼音首字母或PLU号等商品助记符搜索。')
                }}
              </div>
            </div>
            <div class="item">
              <div class="i"></div>
              <div class="text">
                {{ $t('傲视AI视觉识别核心算法，适应不同光线环境及多种秤盘。') }}
              </div>
            </div>
          </div>
          <!-- <div class="img">
            <img src="../../../assets/AI收银弹窗解决方案/界面配图.png" alt="" />
          </div> -->
        </div>
      </div>
      <div class="bottomContainer">
        <div class="bottom w">
          <div class="item">
            <div class="title">{{ $t('Windows推荐配置：') }}</div>
            <div>{{ $t('CPU：Intel J1900及以上') }}</div>
            <div>{{ $t('系统：Windows7及以上') }}</div>
            <div>{{ $t('内存：4G') }}</div>
            <div>{{ $t('储存：64G') }}</div>
            <div class="img">
              <img
                src="../../../assets/AI收银弹窗解决方案/win系统配图.png"
                alt=""
              />
            </div>
          </div>
          <div class="item">
            <div class="title">{{ $t('安卓推荐配置：') }}</div>
            <div>{{ $t('芯片：SOC RK3288及以上') }}</div>
            <div>{{ $t('系统：安卓7及以上') }}</div>
            <div>{{ $t('内存：2G') }}</div>
            <div>{{ $t('储存：16G') }}</div>
            <div class="img">
              <img
                src="../../../assets/AI收银弹窗解决方案/安卓配图.png"
                alt=""
              />
            </div>
          </div>
          <div class="item">
            <div class="title">{{ $t('Linux推荐配置：') }}</div>
            <div>{{ $t('CPU：x86 / ARM') }}</div>
            <div>{{ $t('系统：Ubuntu 18 / Debian 11') }}</div>
            <div>{{ $t('内存：2G') }}</div>
            <div>{{ $t('储存：32G') }}</div>
            <div class="img">
              <img
                src="../../../assets/AI收银弹窗解决方案/Linux配图.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.bannerContainer {
  background: url(../../../assets/AI收银弹窗解决方案/收银解决方案配图.png)
    no-repeat center;
  background-size: cover;
  .banner {
    padding: 122px 0px 0px 134px;
    height: 480px;
    .title {
      line-height: 104.26px;
      color: #344f98;
      font-size: 72px;
      font-weight: 700;
    }
    .info {
      width: 634px;
      font-size: 24px;
      color: rgba(128, 128, 128, 1);
      line-height: 34.75px;
    }
  }
}
.content {
  .topContainer {
    background: #fff;
    .top {
      background: url(../../../assets/AI收银弹窗解决方案/界面配图.png) no-repeat
        center;
      background-size: cover;
      padding-left: 124px;
      padding-right: 86px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 500px;
      // .img {
      //   width: 536px;
      //   height: 398px;
      //   img {
      //     width: 100%;
      //   }
      // }
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 33px;
        .i {
          margin-right: 6px;
          border-radius: 50% 50% 50% 50%;
          background: #2a82e4;
          border: 2px solid #ffffff;
          box-shadow: 0px 2px 4px #cbddf4;
          width: 18px;
          height: 18px;
        }
        .text {
          line-height: 28.96px;
          font-size: 20px;
          color: #808080;
        }
      }
    }
  }
  .bottomContainer {
    .bottom {
      display: flex;
      justify-content: space-between;
      height: 360px;
      padding: 25px 40px;
      .item {
        position: relative;
        padding: 27px 39px;
        width: 410px;
        height: 290px;
        border-radius: 20px;
        background: #f7f7f7;
        .title {
          margin-bottom: 12px;
          font-size: 24px;
          font-weight: 700;
          line-height: 34.75px;
          color: #344f98;
        }
        .img {
          position: absolute;
          top: 166px;
          left: 167px;
          width: 214px;
          height: 101px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        div {
          color: #808080;
          line-height: 30px;
          font-size: 20px;
        }
      }
    }
  }
}
</style>
